import { Popover } from '@headlessui/react';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { ACCOUNT_DOMAIN, MAIN_DOMAIN } from 'globalConstants';
import { useToast } from 'hooks';
import {
  filter,
  gt,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isUndefined,
  map,
  reduce,
  toNumber,
  upperFirst,
} from 'lodash';
import moment from 'moment-timezone';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, MouseEvent, useId } from 'react';
import { BiArea, BiBath } from 'react-icons/bi';
import { BsCardImage, BsFillCameraVideoFill, BsSuitHeartFill } from 'react-icons/bs';
import { MdOutlineKingBed, MdOutlineLocationOn } from 'react-icons/md';
import { convertNumberToVietnameseCurrencyShortString } from 'utils';
import { mainClient } from '../../../../apollo/client';
import {
  FileWithFullUrls,
  GET_REAL_ESTATES,
  RealEstate,
} from '../../../../graphql/main/queries/get-real-estates';
import SavePostPopover from '../save-post-popover';
import ToastStack from '../toast-stack';

interface Props {
  type: 'normal' | 'vip' | 'authorized' | 'special' | 'deposit';
  realEstate?: RealEstate;
}

const RealEstateVerticalCard = ({ type, realEstate }: Props) => {
  const router = useRouter();
  const id = useId();
  const { toasts, addToast } = useToast();
  const { currentUser } = useAuthenticationContext();
  const mediaCountObjects = [
    {
      types: ['video', 'youtube'],
      icon: <BsFillCameraVideoFill className='h-[14px] w-[14px]' />,
      count: 0,
    },
    {
      types: ['image'],
      icon: <BsCardImage className='h-[14px] w-[14px]' />,
      count: 0,
    },
  ];
  const propertyObjects = [
    {
      icon: <BiArea className='min-h-[20px] min-w-[20px] text-text2' />,
      content: realEstate?.area ? `${realEstate?.area.toLocaleString()} m\u00B2` : '-',
    },
    {
      icon: <BiBath className='min-h-[20px] min-w-[20px] text-text2' />,
      content: realEstate?.toiletCount ? realEstate?.toiletCount.toLocaleString() : '-',
    },
    {
      icon: <MdOutlineKingBed className='min-h-[20px] min-w-[20px] text-text2' />,
      content: realEstate?.bedroomCount ? realEstate?.bedroomCount.toLocaleString() : '-',
    },
  ];

  const handleOnSave = (event: any) => {
    event.stopPropagation();
    if (!currentUser) {
      event.preventDefault();
      window.location.href = `${ACCOUNT_DOMAIN}/?tab=dangnhap&redirectUrl=${MAIN_DOMAIN}${router.asPath}`;
    }
  };
  const handleOnClickSavePostModal = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleOnSaveRealEstate = (type: 'add' | 'update' | 'delete') => {
    isEqual(type, 'add')
      ? addToast('success', 'Lưu tin đăng thành công!')
      : isEqual(type, 'update')
      ? addToast('success', 'Cập nhật lưu tin thành công!')
      : isEqual(type, 'delete')
      ? addToast('success', 'Hủy lưu tin thành công!')
      : null;
    mainClient.refetchQueries({
      include: [GET_REAL_ESTATES],
    });
  };

  return (
    <Fragment>
      <Link href={!realEstate?.fullSlug ? '#' : `/${realEstate?.fullSlug}`}>
        <a className='relative'>
          <div
            id={id}
            className='group flex flex-col overflow-hidden rounded-[12px] border border-stroke transition-all duration-[500ms] ease-in-out hover:shadow-6'
          >
            <div className='relative'>
              <div className='h-[146px] overflow-hidden'>
                <img
                  src={
                    (realEstate?.mediaUrls?.[0] as FileWithFullUrls)?.originalUrl ??
                    '/images/error-image.svg'
                  }
                  alt={realEstate?.postTitle}
                  className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                />
              </div>
              {!isNil(realEstate?.isForSell) && (
                <span
                  className={`absolute top-[14px] left-0 rounded-tr-[12px] px-[11px] py-[7px] text-[12px] font-[600] uppercase leading-[15px] text-paper ${
                    realEstate?.isForSell ? 'bg-accent1' : 'bg-accent2'
                  }`}
                >
                  {realEstate?.isForSell ? 'bán' : 'thuê'}
                </span>
              )}
              <div
                className={`absolute top-[57px] left-[6px] h-[48px] w-[55px] bg-cover bg-center bg-no-repeat ${
                  isEqual(type, 'special')
                    ? 'bg-[url("/logos/special-stamp.svg")]'
                    : isEqual(type, 'vip')
                    ? 'bg-[url("/logos/vip-stamp.svg")]'
                    : isEqual(type, 'authorized')
                    ? 'bg-[url("/logos/authorized-stamp.svg")]'
                    : isEqual(type, 'deposit')
                    ? 'bg-[url("/logos/deposit-stamp.svg")]'
                    : ''
                }`}
              />
              {!isEmpty(realEstate?.mediaUrls) && (
                <div className='absolute bottom-[5px] right-[6px] flex flex-col space-y-[5px]'>
                  {map(
                    filter(
                      reduce(
                        realEstate?.mediaUrls,
                        (accumulator, mediaUrl) =>
                          map(accumulator, (item) => {
                            if (includes(item.types, mediaUrl.type)) {
                              return { ...item, count: item.count + 1 };
                            }

                            return item;
                          }),
                        mediaCountObjects,
                      ),
                      (mediaCountObject) => gt(mediaCountObject.count, 0),
                    ),
                    (mediaCountObject, mediaCountObjectIndex) => (
                      <div
                        key={mediaCountObjectIndex}
                        className='flex items-center justify-center space-x-[4px] rounded-[5px] bg-[#000000]/[.2] px-[14px] py-[4px] text-paper'
                      >
                        <span className='text-[12px] leading-[14px]'>{mediaCountObject.count}</span>
                        {mediaCountObject.icon}
                      </div>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className='flex flex-col space-y-[16px] py-[12px] px-[16px]'>
              <span
                className={`min-h-[36px] font-[600] line-clamp-2 ${
                  isEqual(type, 'special')
                    ? 'text-badge'
                    : isEqual(type, 'vip')
                    ? 'text-accent1'
                    : isEqual(type, 'authorized') || isEqual(type, 'deposit')
                    ? 'text-primary'
                    : ''
                }`}
              >
                {upperFirst(realEstate?.postTitle)}
              </span>
              <div className='flex min-h-[20px] w-full items-center space-x-[24px]'>
                {map(
                  filter(propertyObjects, (propertyObject) => Boolean(propertyObject.content)),
                  (propertyObject, propertyObjectIndex) => (
                    <div
                      key={propertyObjectIndex}
                      className='flex items-center space-x-[10px] overflow-hidden'
                    >
                      {propertyObject.icon}
                      <span className='truncate'>{propertyObject.content}</span>
                    </div>
                  ),
                )}
              </div>
              <div className='flex min-h-[20px] w-full items-center space-x-[10px]'>
                <MdOutlineLocationOn className='min-h-[20px] min-w-[20px] text-text2' />
                <span className='truncate'>{realEstate?.shortAddress}</span>
              </div>
              <div className='flex min-h-[20px] w-full items-center space-x-[24px]'>
                <div className='flex w-full items-center space-x-[6px]'>
                  <div className='min-h-[20px] min-w-[20px] bg-[url("/icons/price-tag-red.svg")] bg-cover bg-center bg-no-repeat' />
                  <span className='truncate text-[16px] font-[600] leading-[20px] text-alert'>
                    {isNil(realEstate?.price) ||
                    !isNumber(toNumber(realEstate?.price)) ||
                    !toNumber(realEstate?.price)
                      ? 'Thương lượng'
                      : `${convertNumberToVietnameseCurrencyShortString(
                          toNumber(realEstate?.price),
                        )}${realEstate?.isForSell ? '' : '/tháng'}`}
                  </span>
                </div>
                <span className='w-full truncate text-right text-[14px] leading-[16px]'>
                  {!realEstate?.isForSell ||
                  isNil(realEstate?.price) ||
                  !isNumber(toNumber(realEstate?.price)) ||
                  !toNumber(realEstate?.price) ||
                  isNil(realEstate?.area)
                    ? null
                    : `${convertNumberToVietnameseCurrencyShortString(
                        toNumber(realEstate?.price) / toNumber(realEstate?.area),
                      )}/m\u00B2`}
                </span>
              </div>
              <div className='h-[1px] w-full bg-stroke' />
              <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                {realEstate?.createdAt && (
                  <span className='w-full truncate text-[12px] leading-[15px] text-text2'>
                    {moment(realEstate.createdAt).format('DD/MM/YYYY')}
                  </span>
                )}
                <div className='flex w-full items-center justify-end space-x-[16px]'>
                  {currentUser?.id &&
                    realEstate?.creator?.id &&
                    isEqual(currentUser.id, realEstate?.creator.id) &&
                    realEstate?.privateNote && (
                      <div className='h-[20px] w-[20px] bg-[url("/icons/note.svg")] bg-cover bg-center bg-no-repeat' />
                    )}
                  {!isNil(currentUser) && (
                    <Popover>
                      <Popover.Button
                        className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                          realEstate?.isSaved
                            ? 'bg-primary'
                            : 'border border-paper bg-[#000000]/[.2]'
                        }`}
                        onClick={handleOnSave}
                      >
                        <BsSuitHeartFill className='h-[10px] w-[10px] text-paper' />
                      </Popover.Button>
                      <Popover.Panel
                        className='absolute top-[50px] right-[10px] z-[10]'
                        onClick={handleOnClickSavePostModal}
                      >
                        {({ close }) =>
                          !isUndefined(realEstate?.id) && !isUndefined(realEstate) ? (
                            <SavePostPopover
                              realEstateId={realEstate.id}
                              realEstateType={realEstate.realEstateType}
                              isSaved={realEstate.isSaved}
                              saveItem={realEstate.saveItem}
                              onClose={() => close()}
                              onCompleted={handleOnSaveRealEstate}
                            />
                          ) : (
                            <Fragment />
                          )
                        }
                      </Popover.Panel>
                    </Popover>
                  )}
                </div>
              </div>
            </div>
          </div>
        </a>
      </Link>
      <ToastStack toasts={toasts} />
    </Fragment>
  );
};

export default RealEstateVerticalCard;

export const RealEstateVerticalCardSkeleton = () => (
  <div className='flex animate-pulse flex-col overflow-hidden rounded-[12px] border border-secondary'>
    <div className='h-[146px] bg-secondary' />
    <div className='flex flex-col space-y-[16px] px-[16px] py-[12px]'>
      <div className='flex h-[36px] flex-col'>
        <div className='flex h-[18px] items-center'>
          <div className='h-[14px] w-full bg-secondary' />
        </div>
        <div className='flex h-[18px] items-center'>
          <div className='h-[14px] w-full bg-secondary' />
        </div>
      </div>
      <div className='flex h-[20px] items-center space-x-[24px]'>
        <div className='h-[14px] w-[60px] bg-secondary' />
        <div className='h-[14px] w-[60px] bg-secondary' />
        <div className='h-[14px] w-[60px] bg-secondary' />
      </div>
      <div className='flex h-[20px] items-center'>
        <div className='h-[14px] w-full bg-secondary' />
      </div>
      <div className='flex h-[20px] items-center justify-between space-x-[24px]'>
        <div className='h-[16px] w-[80px] bg-secondary' />
        <div className='h-[14px] w-[60px] bg-secondary' />
      </div>
      <div className='h-[1px] w-full bg-secondary' />
      <div className='flex h-[20px] items-center justify-between space-x-[24px]'>
        <div className='h-[12px] w-[70px] bg-secondary' />
        <div className='flex items-center space-x-[24px]'>
          <div className='h-[20px] w-[20px] bg-secondary' />
          <div className='h-[20px] w-[20px] bg-secondary' />
        </div>
      </div>
    </div>
  </div>
);
